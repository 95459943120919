import React from "react"

import { useIntl } from "react-intl"

import { LoginForm } from "~/components/Forms"
import SEO from "~/components/Seo/Seo"
import UserFormTemplate from "~/components/UserFormTemplate/UserFormTemplate"
import useIsClient from "~/hooks/useIsClient"
import withTemplate from "~/templates/withTemplate"

function LoginPage() {
  const intl = useIntl()

  const welcomeMessage = [
    intl.formatMessage({ id: "profile/text:you-are-back" }),
    intl.formatMessage({ id: "profile/text:connect" }),
  ].join(" ")

  const isClient = useIsClient()

  return isClient ? (
    <>
      <SEO
        title={intl.formatMessage({ id: "profile/text:to-login" })}
        description={welcomeMessage}
      />
      <UserFormTemplate title={welcomeMessage} Form={<LoginForm />} />
    </>
  ) : null
}

export default withTemplate(LoginPage)
